import React, {useMemo, useState} from "react";
import {useTable, usePagination} from "react-table";
import {useNavigate, useLocation} from "react-router-dom";

import arrowLeft from '../../assets/images/icons/arrow-left.svg'
import arrowTowLeft from '../../assets/images/icons/arrow_two_left.svg'

import edit from '../../assets/images/icons/edit.svg'
import remove from '../../assets/images/icons/remove.svg'

export const Paginated = ({columns, data}) => {
    let navigate = useNavigate()
    let location = useLocation();
    let subPagePath = location.pathname.slice(location.pathname.lastIndexOf("/"), location.pathname.length)
    let pagePath = location.pathname.split("/")[1]
    console.log(pagePath, 'llllll')

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow
    } = useTable(
        {
            columns,
            data,
            initialState: {pageIndex: 0}
        },
        usePagination
    );

    const {pageIndex, pageSize} = state;

    const paginate = pageNumber => gotoPage(pageNumber)

    const navigateToSell = (page,subPage, id) => {
        console.log(subPage, "himar")
        navigate(`/${page}${subPage}/${id}`)
    }
    return (
        <div className='tableContainer'>
            <table {...getTableProps()} >
                <thead>
                {headerGroups.map((headerGroup, index) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} onClick={() => navigateToSell(pagePath, subPagePath, row.id)} key={row.id}>
                            {row.cells.map((cell, index) => {
                                // console.log(cell.column.Header,'fnvkjn')

                                if (cell.column.Header === 'Action') {
                                    return (
                                        <td className='btnsContent' key={index}>
                                            <button>
                                                <img src={edit} alt="edit" title='edit'/>
                                            </button>
                                            <button>
                                                <img src={remove} alt="remove" title='remove'/>
                                            </button>
                                        </td>
                                    );
                                } else if (cell.column.Header === 'Checkbox') {
                                    return (<p><input type="checkbox"/></p>)
                                } else {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                    )
                                }
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className='footController'>
                <select
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                    className='showItemsSelect'
                >
                    {[10, 25, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>


                <div className='infoPage'>
                    <p>Page</p>
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>
                </div>
                <div className="pageQuantity">
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        <img src={arrowTowLeft} alt="" title=''/>
                    </button>
                    <button onClick={() => previousPage()} disabled={!canPreviousPage} className='nextOneStep'>
                        <img src={arrowLeft} alt=""/>
                    </button>
                    <div className="pageQuantityContent">
                        {
                            pageOptions.map((number, index) => {
                                return (
                                    <button key={index} onClick={() => paginate(number)}>
                                        {number + 1}
                                    </button>
                                )
                            })
                        }
                    </div>
                    <button onClick={() => nextPage()} disabled={!canNextPage} className='nextOneStep'>
                        <img src={arrowLeft} alt="" style={{transform: 'rotate(180deg)'}}/>
                    </button>
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <img src={arrowTowLeft} alt="" title='' style={{transform: 'rotate(180deg)'}}/>
                    </button>
                </div>
                <div className='goToPage'>
                    <p>Go to</p>
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                            const pageNumber = e.target.value
                                ? Number(e.target.value) - 1
                                : 0;
                            gotoPage(pageNumber);
                        }}
                        style={{width: "50px"}}
                    />
                </div>
            </div>
        </div>
    );
};
