import React from "react";


export const AddSubscription = function () {
    return (
        <>
            <h1>Add Subscription</h1>
            <div className="formGroup">
                <div>
                    <label htmlFor="userName">Current Password</label>
                    <input type="text" placeholder='Current Password' id='userName'/>
                </div>
                <div>
                    <label htmlFor="userName">New Password</label>
                    <input type="text" placeholder='New Password' id='userName'/>
                </div>
                <div>
                    <label htmlFor="userName">Confirm Password</label>
                    <input type="text" placeholder='Confirm Password' id='userName'/>
                </div>
                <button className='btn' type={"button"}>Save</button>
            </div>
        </>
    )
}
