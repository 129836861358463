import search from "../../../assets/images/icons/search.svg";
import {Paginated} from "../../../components/table/Paginated";
import dataCharge from "./dataCharg.json";
import {COLUMNS} from "../column";
import React from "react";

export const Charge = function () {
    return (
        <>
            <h1>Agreements / Charge</h1>
            <div className='customers'>
                <div className="headContent">
                    <div className="searchContent">
                        <input type="search" placeholder='Placeholder'/>
                        <img src={search} alt="" title=''/>
                    </div>
                    <button>Text</button>
                </div>

                <Paginated data={dataCharge} columns={COLUMNS}/>
            </div>
        </>
    )
}
