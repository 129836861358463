import React, {useCallback, useEffect, useState, useRef} from "react";
import {Routes, Route, Link, BrowserRouter} from "react-router-dom";
import SideMenu, {menuItems} from "./components/SideMenu";
import "./App.css";
import {SignIn} from "./pages";
import user from './assets/images/user.svg'
import notification from './assets/images/icons/notification.svg'
import profile from './assets/images/icons/profile.svg'
import logOut from './assets/images/icons/logOut.svg'

// import {DetailsSingle} from "./pages/[page,subPage,id]";


function App() {
    const [inactive, setInactive] = useState(false);
    const [privatePage, setPrivatePage] = useState(false);
    const [token, setToken] = useState(null);
    const [showNavMenu, setShowNavMenu] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            setPrivatePage(false)
        } else {
            setPrivatePage(true)
        }
        // console.log(token, 'token')
    }, [token])

    const handleLogin = (token) => {
        setToken(token)
        localStorage.setItem('token', token)
    };


    const handleLogout = useCallback(() => {
        localStorage.removeItem('token')
        setToken('')
    }, [token])


    function useOutsideMenu(ref) {
        useEffect(() => {

            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowNavMenu(false)
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            // return () => {
            //     document.removeEventListener("mousedown", handleClickOutside);
            // };
        }, [ref]);
    }

    const menuRef = useRef(null);
    useOutsideMenu(menuRef);
    // console.log(menuItems, '555555')

    return (
        <div className="App">
            <BrowserRouter>
                {!privatePage ?
                    <>
                        <SideMenu
                            onCollapse={(inactive) => {
                                // console.log(inactive);
                                setInactive(inactive);
                            }}
                        />

                        <div className={`body ${inactive ? "inactive" : ""}`}>
                            <header>
                                <nav>
                                    <button>
                                        <img src={notification} alt="" title=''/>
                                    </button>
                                    <p className='adminName'>Adwin Khoi</p>
                                    <div className="dropDownContent" ref={menuRef}>
                                        <img
                                            src={user}
                                            alt="" title=''
                                            onClick={() => setShowNavMenu(!showNavMenu)}
                                        />
                                        {
                                            showNavMenu &&
                                            <div className="menu">
                                                <Link to="/profile/account">
                                                    <img src={profile} alt=""/>
                                                    Profile
                                                </Link>
                                                <a href="#">
                                                    <img src={notification} alt=""/>
                                                    Notification
                                                </a>
                                                <Link
                                                    to="/sign-in"
                                                    onClick={() => handleLogout()}
                                                >
                                                    <img src={logOut} alt=""/>
                                                    LogOut
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                </nav>
                            </header>

                            <section>
                                <Routes>
                                    {menuItems.map((menu, index) => (
                                        <>
                                            <Route key={menu.name} exact={menu.exact} path={menu.to}
                                                   element={menu.page}/>
                                            {menu.subMenus && menu.subMenus.length > 0
                                                && menu.subMenus.map((subMenu, i) => (
                                                    <Route key={subMenu.name} path={subMenu.to}
                                                           element={subMenu.subPage}/>
                                                ))
                                            }
                                        </>
                                    ))
                                    }
                                    <Route path={'/account'}>
                                        <Route path={'message'}/>
                                    </Route>
                                </Routes>
                            </section>
                            <footer><p>Footer</p></footer>
                        </div>

                    </>
                    :
                    <Routes>
                        <Route path="/" element={<SignIn handleLogin={handleLogin}/>}></Route>
                    </Routes>
                }
            </BrowserRouter>
        </div>
    );
}

export default App;
