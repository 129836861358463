import React from "react";
import {Paginated} from "../../components/table/Paginated";
import data from "./data";
import {COLUMNS} from "./column";
import search from "../../assets/images/icons/search.svg";


export const Customers = function () {
    return (
        <>
            <h1>Customers</h1>
            <div className='customers'>
                <div className="headContent">
                    <div className="searchContent">
                        <input type="search" placeholder='Placeholder'/>
                        <img src={search} alt="" title=''/>
                    </div>
                    <button>Add Customer</button>
                </div>

                <Paginated data={data} columns={COLUMNS}/>
            </div>
        </>
    )
}
