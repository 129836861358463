import {Paginated} from "../../../components/table/Paginated";
import dataCharge from "./dataFromUser.json";
import {COLUMNS} from "../column";
import React from "react";

export const FromUser = function () {
    return (
        <>
            <h1>Requests / From User</h1>
            <div className='customers'>
                <Paginated data={dataCharge} columns={COLUMNS}/>
            </div>
        </>
    )
}
