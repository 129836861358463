import {Link} from "react-router-dom";
import React from "react";

export const Account = function () {
    return (
        <>
            <h1>Profile / Account</h1>
            <div className='profile'>
                <nav>
                    <Link to="/profile/account">
                        account
                    </Link>
                    <Link to="/profile/security">
                        security
                    </Link>
                    <Link to="/profile/notification">
                        notification
                    </Link>
                </nav>

                <div className='account body'>
                    <div className='imageBanner'>
                        <div className="imgContent">
                            <img src="" alt="" title=''/>
                        </div>
                        <div className="controls">
                            <div className="btnsContent">
                                <button>Upload</button>
                                <button className='light'>Remove</button>
                            </div>
                            <p className="info">Recommended size: Width 300px ☓ Height 300px</p>
                        </div>
                    </div>
                    <div className="formGroup">
                        <div>
                            <label htmlFor="userName">Username</label>
                            <input type="text" placeholder='Username' id='userName'/>
                        </div>
                        <div>
                            <label htmlFor="userName">Username</label>
                            <input type="text" placeholder='Username' id='userName'/>
                        </div>
                        <button>Save Changes</button>
                    </div>
                </div>
            </div>
        </>
    )
}
