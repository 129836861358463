import React, {useEffect, useState} from "react";

import logo from "../assets/images/logo.svg";
import icon from "../assets/images/icons/icon.svg"

import {Profile,Agreements, Ipd, Requests, User, Customers, Subscription, SubContent} from '../pages'
import {Account,Security,Notification, Sell, License, Charge, Partner} from '../pages'

import MenuItem from "./MenuItem";
import {DetailsSingle} from "../pages/[page,subPage,id]";
import {useParams} from "react-router-dom";
import {Customer, FromUser} from "../pages/requests/sub";
import {AddSubscription} from "../pages/subscription";


// added more menuItems for testing

export const menuItems = [


    {
        name: "Profile",
        exact: true,
        to: "/home",
        iconSrcName: icon,
        page: <Profile/>,
        subMenus: [
            {name: "Account", to: "/profile/account", subPage: <Account/>,},
            {name: "Security", to: "/profile/security", subPage: <Security/>,},
            {name: "Notification", to: "/profile/notification", subPage: <Notification/>,},
            {name: "DetailsSingle", to: "/:page/:subPage/:id", subPage: <DetailsSingle/>,},

        ],
    },
    {
        name: "Profile",
        exact: true,
        to: "/profile",
        iconSrcName: icon,
        page: <Profile/>,
        // subMenus: [
        //     {name: "Account", to: "/profile/account", subPage: <Account/>,},
        //     {name: "Security", to: "/profile/security", subPage: <Security/>,},
        //     {name: "Notification", to: "/profile/notification", subPage: <Notification/>,},
        // ],
    },

    {
        name: "Agreements",
        exact: true,
        to: `/agreements`,
        iconSrcName: icon,
        page: <Agreements/>,
        subMenus: [
            {name: "Sell", to: "/verify/sell", subPage: <Sell/>,},
            {name: "License", to: "/verify/license", subPage: <License/>,},
            {name: "Charge", to: "/verify/charge", subPage: <Charge/>,},
            {name: "Partner", to: "/verify/partner", subPage: <Partner/>,},

        ],
    },
    {
        name: "IPD",
        exact: true,
        to: `/ipd`,
        iconSrcName: icon,
        page: <Ipd/>,
        subMenus: [
            {name: "Sub Menu 1", to: "/ipd/sub-menu1", subPage: <SubContent/>},
            {name: "Sub Menu 2", to: "/ipd/sub-menu2", subPage: <SubContent/>},
        ],
    },
    {
        name: "Requests",
        exact: true,
        to: `/requests`,
        iconSrcName: icon,
        page: <Requests/>,
        subMenus: [
            {name: "From User", to: "/requests/from-user", subPage: <FromUser/>},
            {name: "Customer", to: "/requests/customer", subPage: <Customer/>},
        ],
    },
    {
        name: "User",
        exact: true,
        to: `/user`,
        iconSrcName: icon,
        page: <User/>,
        subMenus: [
            {name: "Sub Menu 1", to: "/user/sub-menu1", subPage: <SubContent/>},
            {name: "Sub Menu 2", to: "/user/sub-menu2", subPage: <SubContent/>},
        ],
    },
    {
        name: "Customers",
        exact: true,
        to: `/customers`,
        iconSrcName: icon,
        page: <Customers/>,
        subMenus: [
            {name: "Sub Menu 1", to: "/customers/sub-menu1", subPage: <SubContent/>},
            {name: "Sub Menu 2", to: "/customers/sub-menu2", subPage: <SubContent/>},
        ],
    },
    {
        name: "Subscription",
        exact: true,
        to: `/subscription`,
        iconSrcName: icon,
        page: <Subscription/>,
        subMenus: [
            {name: "Add Subscription", to: "/subscription/add-subscription", subPage: <AddSubscription/>},
            // {name: "Sub Menu 2", to: "/subscription/sub-menu2", subPage: <SubContent/>},
        ],
    },
];

const SideMenu = (props) => {
    const [inactive, setInactive] = useState(false);

    useEffect(() => {
        if (inactive) {
            removeActiveClassFromSubMenu();
        }

        props.onCollapse(inactive);
    }, [inactive]);

    //just an improvment and it is not recorded in video :(
    const removeActiveClassFromSubMenu = () => {
        document.querySelectorAll(".sub-menu").forEach((el) => {
            el.classList.remove("active");
        });
    };

    /*just addData little improvement over click function of menuItem
      Now no need to use expand state variable in MenuItem component
    */
    useEffect(() => {
        let menuItems = document.querySelectorAll(".menu-item");
        menuItems.forEach((el) => {
            el.addEventListener("click", (e) => {
                const next = el.nextElementSibling;
                removeActiveClassFromSubMenu();
                menuItems.forEach((el) => el.classList.remove("active"));
                el.classList.toggle("active");
                console.log(next);
                if (next !== null) {
                    next.classList.toggle("active");
                }
            });
        });
    }, []);

    return (
        <div className={`side-menu ${inactive ? "inactive" : ""}`}>
            <div className="side-header">
                <div className="logo">
                    <img src={logo} alt="webscript"/>
                </div>
                <div onClick={() => setInactive(!inactive)} className="toggle-menu-btn">
                    {inactive ? (
                        <i className="bi bi-arrow-right-square-fill"></i>
                    ) : (
                        <i className="bi bi-arrow-left-square-fill"></i>
                    )}
                </div>
            </div>

            <div className="divider"></div>

            <div className="main-menu">
                <ul>
                    {menuItems.map((menuItem, index) => {

                        if (menuItem.name !== "Profile" && menuItem.name !== "DetailsAgreements") {
                            return (
                                <MenuItem
                                    key={index}
                                    name={menuItem.name}
                                    exact={menuItem.exact}
                                    to={menuItem.to}
                                    subMenus={menuItem.subMenus || []}
                                    iconSrcName={menuItem.iconSrcName}
                                    onClick={(e) => {
                                        if (inactive) {
                                            setInactive(false);
                                        }
                                        console.log(menuItem.name,'jfdib')
                                    }}
                                />
                            )
                        }
                    })}
                </ul>
            </div>
        </div>
    );
};

export default SideMenu;
