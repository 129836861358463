import { Constants } from "../constants";
import axios from "axios";


const axiosInstance = axios.create({
    baseURL: Constants.baseUrl,
    timeout: 100000,
});

axiosInstance.interceptors.request.use(async (config) => {
    let token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default axiosInstance

