import search from "../../assets/images/icons/search.svg";
import {Paginated} from "../../components/table/Paginated";
import data from "../user/data.json";
import {COLUMNS} from "../user/column";
import React from "react";

export const Subscription = function () {
    return (
        <>
            <h1>Subscription</h1>
            <div className='customers'>
                <div className="headContent">
                    <div className="searchContent">
                        <input type="search" placeholder='Placeholder'/>
                        <img src={search} alt="" title=''/>
                    </div>
                    <button>Add Subscription</button>
                </div>

                <Paginated data={data} columns={COLUMNS}/>
            </div>
        </>
    )
}