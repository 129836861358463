import {useParams} from "react-router-dom";
import qrImg from '../assets/images/qrImg.svg'

export const DetailsSingle = () => {
    const route = useParams()

    const menuTitle = route.page
    const subMenuTitle = route.subPage
    const id = +route.id

    return (
        <div className='detailsSingleContainer'>
            <h1>
                {menuTitle.charAt(0).toUpperCase() + menuTitle.slice(1) + ' / '}
                {subMenuTitle.charAt(0).toUpperCase() + subMenuTitle.slice(1) + ' / '}
                {id + 1}
            </h1>
            <div className="body">
                <div className=" certificatePhoto">
                    <div className="photo">
                        <p>Certificate Photo</p>
                    </div>
                </div>
                <div className=" formContent">
                    {/*<div className="formGroup">*/}
                    <div>
                        <label htmlFor="certificateDescription">Certificate Description</label>
                        <input type="text" placeholder="#" id="certificateDescription"/>
                    </div>
                    <div>
                        <label htmlFor="agreementType">Agreement Type</label>
                        <input type="text" placeholder="Name" id="agreementType"/>
                    </div>
                    <div>
                        <label htmlFor="nameCertificate">Name of certificate</label>
                        <input type="text" placeholder="Name" id="nameCertificate"/>
                    </div>
                    <div>
                        <label htmlFor="nameConcept">Name of concept</label>
                        <input type="text" placeholder="Name" id="nameConcept"/>
                    </div>
                    <div>
                        <label htmlFor="certificateNumber">Certificate Number</label>
                        <input type="text" placeholder="Name" id="certificateNumber"/>
                    </div>
                    <div>
                        <label htmlFor="dateIssue">Date of Issue</label>
                        <input type="text" placeholder="Name" id="dateIssue"/>
                    </div>
                    <div>
                        <label htmlFor="proprietorName">Proprietor name</label>
                        <input type="text" placeholder="Name" id="proprietorName"/>
                    </div>
                    <div>
                        <label htmlFor="countryIssued">Country Issued</label>
                        <input type="text" placeholder="Name" id="countryIssued"/>
                    </div>
                    {/*    <button>Save Changes</button>*/}
                    {/*</div>*/}

                </div>
                <div className=" certificateQRcode">
                    <div>
                        <img src={qrImg} alt="" title=""/>
                        <p>Certificate QR code</p>
                    </div>
                </div>
                <div className=" baseValue">
                    <div className='formGroup'>
                        <h3>Value</h3>
                        <label htmlFor="baseValue">Base Value</label>
                        <input type="text" placeholder="1600" id="baseValue"/>
                    </div>
                </div>
                <div className=" selectsContent">
                    <div className='formGroup'>
                        <h3>Status</h3>
                        <select placeholder="Name">
                            <option placeholder='Public'>Public</option>
                            <option placeholder='Name 1'>Name 1</option>
                            <option placeholder='Name 2'>Name 2</option>
                            <option placeholder='Name 3'>Name 3</option>
                        </select>
                    </div>
                    <div className='formGroup'>
                        <h3>Categories</h3>
                        <select placeholder="Name">
                            <option placeholder='Sale'>Sale</option>
                            <option placeholder='Name 1'>Name 1</option>
                            <option placeholder='Name 2'>Name 2</option>
                            <option placeholder='Name 3'>Name 3</option>
                        </select>
                    </div>
                </div>
                <div className=" insurance">
                    <div className='formGroup'>
                        <h3>Value</h3>
                        <label htmlFor="baseValue">Base Value</label>
                        <input type="text" placeholder="1600" id="baseValue"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
