import {Link} from "react-router-dom";
import React from "react";

export const Security = function () {
    return (
        <>
            <h1>Profile / Security</h1>
            <div className='profile'>
                <nav>
                    <Link to="/profile/account">
                        account
                    </Link>
                    <Link to="/profile/security">
                        security
                    </Link>
                    <Link to="/profile/notification">
                        notification
                    </Link>
                </nav>

                <div className='security body'>
                    <div className="formGroup">
                        <div>
                            <label htmlFor="userName">Current Password</label>
                            <input type="text" placeholder='Current Password' id='userName'/>
                        </div>
                        <div>
                            <label htmlFor="userName">New Password</label>
                            <input type="text" placeholder='New Password' id='userName'/>
                        </div>
                        <div>
                            <label htmlFor="userName">Confirm Password</label>
                            <input type="text" placeholder='Confirm Password' id='userName'/>
                        </div>
                        <button>Save Changes</button>
                    </div>
                </div>
            </div>
        </>

    )
}
