
import {Paginated} from "../../../components/table/Paginated";
import dataCustomer from "./dataCustomer.json";
import {COLUMNS} from "../column";
import React from "react";

export const Customer = function () {

    return (
        <>
            <h1>Requests / Customers</h1>
            <div className='customers'>
                <Paginated data={dataCustomer} columns={COLUMNS}/>
            </div>
        </>
    )
}
