import {Link} from "react-router-dom";
import profile from "../../assets/images/icons/profile.svg";
import React from "react";

export const Profile = function () {
    return (
        <>
            <h1>Profile</h1>
            <div className='profile'>
                <nav>
                    <Link to="profile/account">
                        account
                    </Link>
                    <Link to="profile/security">
                        security
                    </Link>
                    <Link to="profile/notification">
                        notification
                    </Link>
                </nav>
                <div className='body'>
                    body
                </div>
            </div>
        </>

    )
}
