import {Link} from "react-router-dom";
import React from "react";

export const Notification = function () {
    return (
        <>
            <h1>Profile / Notification</h1>

            <div className='profile'>
                <nav>
                    <Link to="/profile/account">
                        account
                    </Link>
                    <Link to="/profile/security">
                        security
                    </Link>
                    <Link to="/profile/notification">
                        notification
                    </Link>
                </nav>

                <div className='notification body'>

                </div>
            </div>
        </>
    )
}
