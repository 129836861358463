
import {Paginated} from "../../components/table/Paginated";
import data from "./data.json";
import {COLUMNS} from "./column";
import React from "react";

export const Requests = function () {
    return (
        <div className='requests'>
            <h1>Agreements / License</h1>
            <div className='customers'>
                <Paginated data={data} columns={COLUMNS}/>
            </div>
        </div>
    )
}
