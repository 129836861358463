import React, {useState} from "react";
import logo from "../assets/images/logo.svg";
import {useNavigate} from "react-router-dom";
import * as _ from "lodash";
import axiosInstance from "../api";


export function SignIn({handleLogin}) {
    let navigate = useNavigate()
    let [error, setError] = useState('')
    let [data, setData] = useState({})
    console.log(data);
    let changeInput = async (e, name) => {
        setData({..._.assignIn(data, {[name]: e})})
        setError('')
    }

    const EmailValidation = () => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        re.test(String(data.email).toLowerCase());
        return re.test(String(data.email).toLowerCase());
    }

    const signIn = async (e) => {
        try {
            if(data.email && data.password){
                if(EmailValidation()){
                    if(data.password.length > 7){
                        let response = await axiosInstance.post( 'api/admin/login', data)
                        console.log(response, 'rfg');
                        localStorage.setItem('token_admin', response.data._token);
                        handleLogin({
                            token: response.data.token
                        })
                        navigate.to("/home")
                    }else{
                        setError('Length must be at least 8 characters')
                    }
                }else{
                    setError('Please enter valid email address')
                }
            }else{
                setError('Please fill in all required fields')
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    }



    return (
        <div className='signBanner'>
            <img src={logo} alt="" title="" className='logo'/>
            <form >
                <label htmlFor="email">
                    Email
                    <input type="email" id='email' placeholder='Email'  onChange={(e) => changeInput(e.target.value, 'email')}/>
                </label>
                <label htmlFor="pass" style={{marginBottom: 0}}>
                    Password
                    <input type="password" id='pass' placeholder='Password' onChange={(e) => changeInput(e.target.value, 'password')}/>
                </label>
                <div style={{justifyContent: 'space-between', marginTop: '1.1rem'}}>
                    <label htmlFor="rememberMe" >
                        <input type="checkbox" id='rememberMe' />
                        Remember me next time
                    </label>
                    <button>Forgot Password</button>
                </div>
                {error ?
                    <p style={{color: 'red', marginTop: 10}}>{error}</p>
                    :
                    null
                }
                <button className='btn' type={"button"} onClick={signIn}>Sign In</button>

            </form>
        </div>
    );
}
